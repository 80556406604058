<template>
  <div class="header" ref="header">
    <div class="header__gradient--1"></div>
    <div class="header__gradient--2"></div>
    <div class="header__wrapper" ref="headerWrapper">
      <div class="header__hl">HTML5-Ads.com</div>
    </div>
  </div>
</template>

<script>
import getPathFunction from "@/utilities/composition/getPathFunction";
import { ref } from "@vue/runtime-core";
export default {
  props: { imgpath: String, imgposition: String, motion: String },
  setup() {
    const { setBackGroundPath } = getPathFunction();

    const headerWrapper = ref(null);
    const header = ref(null);

    return { setBackGroundPath, headerWrapper, header };
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: relative;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;

  @media screen and (min-width: $small) {
  }
  @media screen and (min-width: $medium) {
  }
  @media screen and (min-width: $large) {
  }
  @media screen and (min-width: $xlarge) {
  }
}
.header--aspectratio {
  position: relative;
  width: 100%;
  margin-bottom: 50px;

  @media screen and (min-width: $small) {
    margin-bottom: 75px;
  }
  @media screen and (min-width: $medium) {
    margin-bottom: 100px;
  }
  @media screen and (min-width: $large) {
    margin-bottom: 130px;
  }
  @media screen and (min-width: $xlarge) {
    margin-bottom: 130px;
  }
}

.header__hl {
  color: white;
  font-size: 5vw;
  text-transform: uppercase;
  font-weight: 900;
  @media screen and (min-width: $small) {
  }
  @media screen and (min-width: $medium) {
  }
  @media screen and (min-width: $large) {
  }
  @media screen and (min-width: $xlarge) {
  }
}

.header__wrapper {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;
}
.header__img {
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.header__gradient--2 {
  position: absolute;
  width: 100%;
  height: 0;
  padding-top: 100%;

  left: 50%;
  top: -30%;

  background: rgba(104, 252, 240, 1);
  background: -moz-radial-gradient(
    center,
    ellipse cover,
    rgba(104, 252, 240, 1) 0%,
    rgba(186, 255, 241, 0.02) 60%,
    rgba(186, 255, 241, 0) 61%
  );
  background: -webkit-gradient(
    radial,
    center center,
    0px,
    center center,
    100%,
    color-stop(0%, rgba(104, 252, 240, 1)),
    color-stop(60%, rgba(186, 255, 241, 0.02)),
    color-stop(61%, rgba(186, 255, 241, 0))
  );
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    rgba(104, 252, 240, 1) 0%,
    rgba(186, 255, 241, 0.02) 60%,
    rgba(186, 255, 241, 0) 61%
  );
  background: -o-radial-gradient(
    center,
    ellipse cover,
    rgba(104, 252, 240, 1) 0%,
    rgba(186, 255, 241, 0.02) 60%,
    rgba(186, 255, 241, 0) 61%
  );
  background: -ms-radial-gradient(
    center,
    ellipse cover,
    rgba(104, 252, 240, 1) 0%,
    rgba(186, 255, 241, 0.02) 60%,
    rgba(186, 255, 241, 0) 61%
  );
  background: radial-gradient(
    ellipse at center,
    rgba(104, 252, 240, 1) 0%,
    rgba(186, 255, 241, 0.02) 60%,
    rgba(186, 255, 241, 0) 61%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#68fcf0', endColorstr='#bafff1', GradientType=1 );
}
.header__gradient--1 {
  position: absolute;
  width: 100%;
  height: 100%;

  background: rgba(168, 0, 201, 1);
  background: -moz-linear-gradient(
    left,
    rgba(168, 0, 201, 1) 0%,
    rgba(179, 70, 252, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(168, 0, 201, 1)),
    color-stop(100%, rgba(179, 70, 252, 1))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(168, 0, 201, 1) 0%,
    rgba(179, 70, 252, 1) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(168, 0, 201, 1) 0%,
    rgba(179, 70, 252, 1) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(168, 0, 201, 1) 0%,
    rgba(179, 70, 252, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(168, 0, 201, 1) 0%,
    rgba(179, 70, 252, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a800c9', endColorstr='#b346fc', GradientType=1 );
}
</style>
