<template>
  <div class="page page__home">
    <HeaderGradient :imgpath="'assets/img/header/1.jpg'" />
    <!-- <LoveBrandsBlock class="section" />
    <SDDBlock class="section" />
    <GalleryProjekts class="section" />
    <KundenBlock class="section" /> -->

    <!-- <ContactBlock class="section" /> -->
    <TheFooter class="section" />
  </div>
</template>

<script>
import HeaderGradient from "@/components/common/header/HeaderGradient";
// import LoveBrandsBlock from "@/components/common/loveBrands/LoveBrandsBlock.vue";
// import SDDBlock from "@/components/common/sdd/SDDBlock.vue";
// import GalleryProjekts from "@/components/common/gallery/GalleryProjekts.vue";
// import KundenBlock from "@/components/common/kunden/KundenBlock.vue";
// import ContactBlock from "@/components/common/contact/ContactBlock.vue";
import TheFooter from "../components/common/footer/TheFooter.vue";

import { useStore } from "vuex";

export default {
  components: {
    HeaderGradient,
    // ContactBlock,

    TheFooter,
  },

  setup() {
    const store = useStore();
    // MENU STYLINGS:

    return { store };
  },
};
</script>
<style
  lang="
    scss"
></style>
